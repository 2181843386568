import React from 'react'
import "../../Assets/Styles/Schedule/schedule.scss";

const SideContainer = () => {
  return (
    <div className='side-container'>
      SideContainer
    </div>
  )
}

export default SideContainer